import orderLogic from 'public/src/pages/common/orderLogic'
import { template, isNumber } from '@shein/common-function'

export const handlerDate = date => {
  if (typeof window === 'undefined') return ''
  return orderLogic.orderDateFormat(date, false)
}

// 格式化时间
export const formatTime = time => {
  let timeString = ''
  if (time) {
    if (/^\d{10,13}$/.test(time)) {
      timeString = handlerDate(time)
    } else if (/^\d{10,13}-\d{10,13}$/.test(time)) {
      const transportTime = time.split('-')
      const startTime = handlerDate(transportTime[0])
      const endTime = handlerDate(transportTime[1])
      timeString = `${startTime} ~ ${endTime}`
    } else {
      timeString = time
    }
  }
  return timeString
}

// 格式化双时效文案
export const formatDoubleTime = (params = {}) => {
  let {
    originShippingDesc,
    originDeliveryDesc,
    transportTimeType,
    transportTime,
    subTransportTime,
    useEnum = false,
  } = params
  if (!originShippingDesc || !originDeliveryDesc || !subTransportTime) {
    return ''
  }
  if (useEnum) {
    transportTimeType = transportTimeType - 1
  }

  if (transportTimeType == 0) {
    const shippingDesc = originShippingDesc.replace('%s', transportTime)
    const deliveryDesc = originDeliveryDesc.replace(
      '%s',
      formatTime(subTransportTime)
    )

    return `${shippingDesc} (${deliveryDesc})`
  } else {
    const shippingDesc = originShippingDesc.replace('%s', subTransportTime)
    const deliveryDesc = originDeliveryDesc.replace(
      '%s',
      formatTime(transportTime)
    )
    return `${deliveryDesc} (${shippingDesc})`
  }
}

const replaceQuickShip = (quickShipDesc = '', quickShipWord = '') => {
  if (typeof quickShipDesc != 'string') return ''
  return quickShipDesc.replace(/{QSname}/g, quickShipWord)
}

// 根据配置格式化quick_ship_desc文案
export const formatQuickShipDesc = (params = {}) => {
  const { 
    quickShipDesc = '', 
    countryId = '', 
    isCurrentSite = false, 
    language = {}, 
    quickShipAbt = {}, 
    ORDER_QUICK_SHIP_WORD = {} 
  } = params
  if (!quickShipDesc || typeof quickShipDesc !== 'string') return ''
  if (quickShipAbt.orderQuickshipStyle == 'on') {
    // 如果abt开启、订单是当前站点且abt中orderQuickshipWord有值则取abt的值
    const orderQuickShipWord = quickShipAbt.orderQuickshipWord
    if (isCurrentSite && orderQuickShipWord > 0) {
      return replaceQuickShip(quickShipDesc, 
        template(orderQuickShipWord, language.SHEIN_KEY_PWA_30600)
      )
    }
    // 如果abt开启、订单非当前站点且阿波罗中有与当前订单国家匹配的配置，则取阿波罗的值
    const apolloItem = ORDER_QUICK_SHIP_WORD?.[countryId] || {}
    if (!isCurrentSite && apolloItem.orderQuickshipWord > 0) {
      return replaceQuickShip(quickShipDesc, 
        template(apolloItem.orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
      )
    }
  }
  return replaceQuickShip(quickShipDesc, language.SHEIN_KEY_PWA_22276)
}

// 根据配置格式化quick_ship标签文案
export const formatQuickShipLabel = (params = {}) => {
  const { 
    countryId = '', 
    isCurrentSite = false, 
    language = {}, 
    quickShipAbt = {}, 
    ORDER_QUICK_SHIP_WORD = {} 
  } = params
  if (quickShipAbt.orderQuickshipStyle == 'on') {
    // 如果abt开启、订单是当前站点且abt中orderQuickshipWord有值则取abt的值
    const orderQuickShipWord = quickShipAbt.orderQuickshipWord
    if (isCurrentSite && orderQuickShipWord > 0) {
      return template(
        quickShipAbt.orderQuickshipWord,
        language.SHEIN_KEY_PWA_30600
      )
    }
    // 如果abt开启、订单非当前站点且阿波罗中有与当前订单国家匹配的配置，则取阿波罗的值
    const apolloItem = ORDER_QUICK_SHIP_WORD?.[countryId] || {}
    if (!isCurrentSite && apolloItem.orderQuickshipWord > 0) {
      return template(
        apolloItem.orderQuickshipWord,
        language.SHEIN_KEY_PWA_30600
      )
    }
  }
  return language.SHEIN_KEY_PWA_22276
}

// 获取催派的时间类型
export const getTimeType = (packageInfo = {}) => {
  let {
    time_type: timeType, // 运输时间: qs和大件:1:运输时间 2:送达时间 | 普通时效:0:运输时间 1:送达时间
    type, // 时效类型：0-普通时效 1-qs时效 2-大件时效
  } = packageInfo || {}
  if (timeType == 999) return timeType
  // 如果不是普通时效，使用新枚举字段
  if (isNumber(type) && type != 0) {
    timeType -= 1
  }
  return timeType
}


