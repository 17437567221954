<template>
  <div>
    <s-dialog :visible="visible">
      <template slot="title">
        <div class="cond-text">
          {{ content }}
        </div>
      </template>
      <template slot="footer">
        <s-button
          :type="['primary']"
          :width="'100%'"
          @click.native.stop="$emit('closeMallNmaeDialog')"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
export default {
  props: {
    language: {
      type: Object,
      default: ()=>{}
    },
    content: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style>
.cond-text {
  word-break: break-word;
}
</style>
