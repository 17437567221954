const tipList = {
  1: 'SHEIN_KEY_PWA_18800',
  2: 'SHEIN_KEY_PWA_18801',
  3: 'SHEIN_KEY_PWA_18802',
  4: 'SHEIN_KEY_PWA_18803',
  5: 'SHEIN_KEY_PWA_18804',
  6: 'SHEIN_KEY_PWA_18805',
  7: 'SHEIN_KEY_PWA_18801',
  8: 'SHEIN_KEY_PWA_18806',
  9: 'SHEIN_KEY_PWA_31566',
  10: 'SHEIN_KEY_PWA_18807',
  11: 'SHEIN_KEY_PWA_31567',
  12: 'SHEIN_KEY_PWA_18808',
  16: 'SHEIN_KEY_PWA_27062',
  17: 'SHEIN_KEY_PWA_27062',
  18: 'SHEIN_KEY_PWA_27063',
  19: 'SHEIN_KEY_PWA_27063',
  20: 'SHEIN_KEY_PWA_27293',
  21: 'SHEIN_KEY_PWA_27293',
  22: 'SHEIN_KEY_PWA_31684',
  default: 'SHEIN_KEY_PWA_18809'
}

export { tipList }
