<template>
  <s-drawer
    :visible.sync="showDialog"
    class="customrecom-address"
    :show-close="true"
    :close-on-click-modal="false"
    @close-from-icon="close"
  >
    <template slot="top">
      {{ language.SHEIN_KEY_PWA_25863 }}
    </template>
    <div class="customrecom-address__content">
      <span class="content-text">
        {{ language.SHEIN_KEY_PWA_25865 }}
      </span>
      <s-loading
        type="curpage"
        :show="isLoading"
        :container-cover-style="{
          position: 'absolute',
          top: '1.07rem'
        }"
      />
      <div
        v-if="!isLoading"
        class="display-contianer"
      >
        <div
          v-for="item in listData"
          :key="item.name"
          class="content-display"
        >
          <div
            class="content-display__title"
            :class="{
              'content-display__contrast': item.name === 'secondData'
            }"
          >
            <span>{{ item.title }}</span>
            <div
              class="triangle"
              :class="{
                'contrast-triangle': item.name === 'secondData'
              }"
            ></div>
          </div>
          <div class="content-display__item">
            <span class="item-icon"> <i class="suiiconfont sui_icon_me_fill_15px"></i></span>
            <div>
              <span
                v-for="(element, index) in item.firstRow"
                :key="element.key + index"
                :class="{
                  'different': element.isDifferent,
                  'hiden': !element.value
                }"
                class="first-content"
              >
                {{ element.value }}</span>
            </div>
          </div>
          <div class="content-display__item">
            <span class="item-icon"> <i class="suiiconfont sui_icon_location_fill_16px"></i></span>
            <div>
              <div>
                <span
                  v-for="(element, index) in item.secondRow"
                  :key="element.key + index"
                  :class="{
                    'different': element.isDifferent,
                    'hiden': !element.value
                  }"
                  class="second-content"
                >{{ element.value }}</span>
              </div>
              <div>
                <span
                  v-for="(element, index) in item.thirdRow"
                  :key="element.key + index"
                  :class="{
                    'different': element.isDifferent,
                    'hiden': !element.value
                  }"
                  class="second-content"
                >{{ element.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-footer">
        <SButton
          :type="['default', 'H72PX']"
          :min-width="'48%'"
          :disabled="disabledBtn"
          @click="closeBtnHandle"
        >
          {{ language.SHEIN_KEY_PWA_25874 }}
        </SButton>
        <SButton
          :type="['primary', 'H72PX']"
          :min-width="'48%'"
          :disabled="disabledBtn"
          @click="submitData"
        >
          {{ language.SHEIN_KEY_PWA_25873 }}
        </SButton>
      </div>
    </div>
  </s-drawer>
</template>
<script>
export default {
  name: 'CustomerSyncToUserAddress'
}
</script>
<script setup>
import { onMounted, ref, toRefs, watch } from 'vue'
import schttp from 'public/src/services/schttp'
import {
  convertAddress,
  sortAddressCardInfo
} from 'public/src/pages/components/address/config/index'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Button as SButton, Toast } from '@shein/sui-mobile'

daEventCenter.addSubscriber({ modulecode: '2-29' })

const showDialog = ref(false)

const props = defineProps({
  controlDialog: { type: Boolean, default: false },
  orderData: { type: Object, default: () => {} },
  language: { type: Object, default: () => {} }
})
const emit = defineEmits(['controlDialogClose', 'recomAddressClose'])

const { controlDialog, orderData, language } = toRefs(props)

const isLoading = ref(false)
const disabledBtn = ref(true)

/** 埋点操作 */
const buryPointHandle = (type, data) => {
  daEventCenter.triggerNotice({
    daId: '2-29-1',
    extraData: {
      name: 'click_customer_sync_to_address_pop-ups',
      click_type: type,
      click_result: data
    }
  })
}

/** 弹窗关闭 */
const close = () => {
  showDialog.value = false
  emit('controlDialogClose')
  buryPointHandle(0, { code: '', msg: '' })
}

/** 列表展示数据 */
const listData = ref([])
watch(
  () => controlDialog.value,
  val => {
    showDialog.value = val
  },
  { immediate: true }
)
const firstRowKey = ['tel']
const secondRowKey = ['address1', 'address2']
const thirdRowKey = [
  'district',
  'districtSecondLanguage',
  'city',
  'citySecondLanguage',
  'state',
  'stateSecondLanguage'
]
const thirdRowAloneKey = ['countryName', 'postcode']

/** 语种拼接 */
const languageJoin = list => {
  return list.filter(Boolean).join('/')
}

/** 对象中获取数据 */
const getDatafromLoop = (list, obj, flag, data) => {
  list.forEach(item => {
    obj[flag].push({
      value: data?.[item] || '',
      isDifferent: false,
      key: item
    })
  })
}

/** 名称的操作 */
const handleName = (obj, flag, data) => {
  obj[flag].push(
    ...sortAddressCardInfo({
      detail: data,
      needFilter: false
    }).map((item, index) => ({
      key: `name${index}`,
      value: item,
      isDifferent: false
    }))
  )
}

/** 组装数据 */
const handleData = (tempObj, data) => {
  handleName(tempObj, 'firstRow', data)
  getDatafromLoop(firstRowKey, tempObj, 'firstRow', data)
  getDatafromLoop(secondRowKey, tempObj, 'secondRow', data)

  //特殊处理管理室代收
  if (data.countryId === '209' && data.type === '3') {
    tempObj.secondRow.push({
      value: `(${language.value.SHEIN_KEY_PWA_25881})`,
      isDifferent: false,
      key: 'caretakerRoom'
    })
  }

  //特殊处理多语言
  for (let i = 0; i < thirdRowKey.length; ) {
    tempObj.thirdRow.push({
      value: languageJoin([data?.[thirdRowKey[i]], data?.[thirdRowKey[i + 1]]]) || '',
      isDifferent: false,
      key: thirdRowKey[i]
    })
    i += 2
  }
  getDatafromLoop(thirdRowAloneKey, tempObj, 'thirdRow', data)
}

/** 判断数据是否不同 */
const judgeDifferently = (origin, contrast, splitFlag = false) => {
  origin.forEach(item => {
    const tempItem = contrast.find(element => element.key === item.key)
    if (tempItem) {
      if (!splitFlag) {
        if (tempItem.value !== item.value) {
          tempItem.isDifferent = true
        }
      } else {
        if (tempItem.value.split('/')[0] !== item.value.split('/')[0]) {
          tempItem.isDifferent = true
        }
      }
    }
  })
}

// 订单地址信息
const orderAddressData = ref({})
/** 初始化数据 */
const initData = async () => {
  isLoading.value = true
  disabledBtn.value = true
  daEventCenter.triggerNotice({
    daId: '2-29-1',
    extraData: {
      name: 'expose_customer_sync_to_address_pop-ups'
    }
  })
  try {
    const result = await Promise.all([
      schttp({
        url: `/api/user/addressbook/singleAddress/get`,
        params: {
          addressId: orderData.value.address_id
        }
      }),
      schttp({
        method: 'POST',
        url: '/api/orders/base/queryAddressInfo/query',
        data: {
          billno: orderData.value.billno
        }
      })
    ])
    isLoading.value = false
    if (!result?.[0]?.info?.address || !result?.[1]?.info?.shipping) return
    disabledBtn.value = false
    orderAddressData.value = convertAddress({
      from: result[1].info.shipping,
      type: 'orderToInterface'
    })
    const firstData = result[0].info.address
    const tempObjFirst = {
      name: 'firtData',
      title: language.value.SHEIN_KEY_PWA_25866,
      firstRow: [],
      secondRow: [],
      thirdRow: []
    }
    const secondData = convertAddress({
      from: result[1].info.shipping,
      type: 'shippingToEdit'
    })
    secondData.countryName = secondData?.country || ''

    const tempObjSecond = {
      name: 'secondData',
      title: language.value.SHEIN_KEY_PWA_25876,
      firstRow: [],
      secondRow: [],
      thirdRow: []
    }

    handleData(tempObjFirst, firstData)
    handleData(tempObjSecond, secondData)
    judgeDifferently(tempObjFirst.firstRow, tempObjSecond.firstRow)
    judgeDifferently(tempObjFirst.secondRow, tempObjSecond.secondRow)
    judgeDifferently(tempObjFirst.thirdRow, tempObjSecond.thirdRow, true)
    listData.value = [tempObjFirst, tempObjSecond]
  } catch (e) {
    isLoading.value = false
  }
}
/** 订单地址是否同步 */
const synchronismAddressStatus = async flag => {
  const params = {
    billno: orderData.value.billno,
    operation_type: flag
  }
  await schttp({
    method: 'POST',
    url: `/api/orders/base/need/status/get`,
    data: params
  })
}

/** 关闭按钮操作 */
const closeBtnHandle = async () => {
  buryPointHandle(2, { code: '', msg: '' })
  showDialog.value = false
  await synchronismAddressStatus(1)
  emit('recomAddressClose')
}

/** 数据提交修改 */
const submitData = async () => {
  if (!orderData.value.address_id) return
  const result = await schttp({
    method: 'POST',
    url: '/api/user/addressbook/list/update',
    params: {
      addressId: orderData.value.address_id
    },
    data: orderAddressData.value
  })
  buryPointHandle(1, { code: result.code, msg: result.msg })
  if (result.code !== '0') {
    Toast({
      content: language.value.SHEIN_KEY_PWA_25869
    })
    return
  }
  await synchronismAddressStatus(0)
  emit('recomAddressClose')
  Toast({
    content: language.value.SHEIN_KEY_PWA_25868
  })
}

onMounted(() => {
  initData()
})
</script>
<style lang="less" scoped>
.customrecom-address {
  &__content {
    width: 100%;
    padding: 0.32rem;
    box-sizing: border-box;
    .content-text {
      word-wrap: break-word;
      width: 100%;
      display: inline-block;
      margin-bottom: 0.666rem;
      color: @sui_color_gray_dark1;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      font-size: 0.373rem;
      font-family: SF UI Text;
    }
    .display-contianer {
      width: 100%;
      padding: 0 0.32rem;
      box-sizing: border-box;
    }
    .content-display {
      width: 100%;
      box-sizing: border-box;
      padding: 0.48rem 0.32rem 0.32rem 0.32rem;
      background-color: @sui_color_gray_weak2;
      margin-bottom: 0.666rem;
      position: relative;

      &__title {
        position: absolute;
        background-color: @sui_color_gray_dark2;
        top: 0;
        left: 0;
        transform: translate(-0.16rem, -50%);
        display: inline-flex;
        height: 0.426rem;
        padding: 0px 0.106rem;
        justify-content: center;
        align-items: center;

        span {
          color: @sui_color_white;
          font-family: SF UI Text;
          font-size: 0.32rem;
          font-style: normal;
          font-weight: 500;
          display: inline-block;
        }

        .triangle {
          position: absolute;
          top: 0.42rem;
          left: 0px;
          width: 0.186rem;
          height: 0.186rem;
          background: linear-gradient(
            45deg,
            transparent,
            transparent 50%,
            @sui_color_black_alpha80 50%,
            @sui_color_black_alpha80 100%
          );
        }
      }

      &__contrast {
        background-color: @sui_color_micro_emphasis;

        .contrast-triangle {
          background: linear-gradient(
            45deg,
            transparent,
            transparent 50%,
            @sui_color_club_rosegold_dark1 50%,
            @sui_color_club_rosegold_dark1 100%
          );
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__item {
        width: 100%;
        display: flex;
        gap: 0.186rem;
        margin-bottom: 0.213rem;

        &:last-child {
          margin-bottom: 0;
        }
        .item-icon {
          i {
            color: @sui_color_gray_dark1;
          }
        }
        .first-content {
          font-family: SF UI Text;
          font-size: 0.32rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.533rem;
          color: @sui_color_gray_dark1;
          // margin-right: 0.133rem;
          word-break: break-all;
        }
        .second-content {
          font-family: SF UI Text;
          font-size: 0.373rem;
          font-style: normal;
          font-weight: 400;
          color: @sui_color_gray_dark1;
          margin-right: 0.133rem;
          word-break: break-all;
        }
        .different {
          color: @sui_color_micro_emphasis;
        }
        .hiden {
          display: none;
        }
      }
    }
    .content-footer {
      margin-top: 0.906rem;
      display: flex;
      gap: 0.32rem;
    }
  }
}
</style>
