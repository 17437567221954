var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-drawer',{staticClass:"customrecom-address",attrs:{"visible":_setup.showDialog,"show-close":true,"close-on-click-modal":false},on:{"update:visible":function($event){_setup.showDialog=$event},"close-from-icon":_setup.close}},[_c('template',{slot:"top"},[_vm._v("\n    "+_vm._s(_setup.language.SHEIN_KEY_PWA_25863)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"customrecom-address__content"},[_c('span',{staticClass:"content-text"},[_vm._v("\n      "+_vm._s(_setup.language.SHEIN_KEY_PWA_25865)+"\n    ")]),_vm._v(" "),_c('s-loading',{attrs:{"type":"curpage","show":_setup.isLoading,"container-cover-style":{
        position: 'absolute',
        top: '1.07rem'
      }}}),_vm._v(" "),(!_setup.isLoading)?_c('div',{staticClass:"display-contianer"},_vm._l((_setup.listData),function(item){return _c('div',{key:item.name,staticClass:"content-display"},[_c('div',{staticClass:"content-display__title",class:{
            'content-display__contrast': item.name === 'secondData'
          }},[_c('span',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"triangle",class:{
              'contrast-triangle': item.name === 'secondData'
            }})]),_vm._v(" "),_c('div',{staticClass:"content-display__item"},[_c('span',{staticClass:"item-icon"},[_c('i',{staticClass:"suiiconfont sui_icon_me_fill_15px"})]),_vm._v(" "),_c('div',_vm._l((item.firstRow),function(element,index){return _c('span',{key:element.key + index,staticClass:"first-content",class:{
                'different': element.isDifferent,
                'hiden': !element.value
              }},[_vm._v("\n              "+_vm._s(element.value))])}),0)]),_vm._v(" "),_c('div',{staticClass:"content-display__item"},[_c('span',{staticClass:"item-icon"},[_c('i',{staticClass:"suiiconfont sui_icon_location_fill_16px"})]),_vm._v(" "),_c('div',[_c('div',_vm._l((item.secondRow),function(element,index){return _c('span',{key:element.key + index,staticClass:"second-content",class:{
                  'different': element.isDifferent,
                  'hiden': !element.value
                }},[_vm._v(_vm._s(element.value))])}),0),_vm._v(" "),_c('div',_vm._l((item.thirdRow),function(element,index){return _c('span',{key:element.key + index,staticClass:"second-content",class:{
                  'different': element.isDifferent,
                  'hiden': !element.value
                }},[_vm._v(_vm._s(element.value))])}),0)])])])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content-footer"},[_c(_setup.SButton,{attrs:{"type":['default', 'H72PX'],"min-width":'48%',"disabled":_setup.disabledBtn},on:{"click":_setup.closeBtnHandle}},[_vm._v("\n        "+_vm._s(_setup.language.SHEIN_KEY_PWA_25874)+"\n      ")]),_vm._v(" "),_c(_setup.SButton,{attrs:{"type":['primary', 'H72PX'],"min-width":'48%',"disabled":_setup.disabledBtn},on:{"click":_setup.submitData}},[_vm._v("\n        "+_vm._s(_setup.language.SHEIN_KEY_PWA_25873)+"\n      ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }